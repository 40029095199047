<template>
  <b-card text-variant="opalean-gray-dark" class="card-custom gutter-b w-100 h-100">
    <!-- Overlay de chargement -->
    <div class="spinner-overlay" v-if="isLoaded">
      <output class="spinner-border text-primary" aria-live="polite">
        <span class="sr-only">Loading...</span>
      </output>
    </div>

    <!-- Contenu principal de la carte -->
    <b-card-text class="d-flex flex-column align-items-center justify-content-center h-100">
      <!-- Zone de dépôt de fichiers -->
      <div
        class="drop-area"
        :class="{ 'drag-over': isDragOver }"
        @dragover.prevent="onDragOver"
        @dragenter.prevent="onDragEnter"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop"
      >
        <!-- Icône de téléchargement -->
        <span class="svg-icon svg-icon-9x mb-8">
          <inline-svg src="media/svg/icons/Files/Cloud-upload.svg" />
        </span>

        <!-- Texte d'instruction -->
        <span class="font-size-h6 text-start font-weight-bold mb-7" style="text-align: center" v-translate> Drag and drop to Upload File </span>

        <!-- Bouton "Ou" -->
        <span class="font-size-h6 text-start font-weight-bold mb-9" style="text-align: center" v-translate>Or</span>
        <!-- Input de fichier caché -->
        <input type="file" ref="fileInput" class="d-none" accept=".xls,.xlsx" />
        <!-- Bouton "Parcourir" -->
        <b-button variant="outline-primary" class="btn btn-outline-primary font-weight-bolder font-size-h6 px-8 py-4 mb-3" @click="browseFile" v-translate
          >Browse File</b-button
        >
        <div class="mt-4l w-100">
          <b-form-textarea v-model="pastedContent" placeholder="Or paste your data here..." rows="3" max-rows="6" @paste="onPaste"></b-form-textarea>
        </div>
      </div>
    </b-card-text>

    <!-- Pied de page de la carte -->
    <template v-slot:footer>
      <div class="d-flex justify-content-between align-items-center w-100">
        <!-- Informations sur les types de fichiers supportés -->
        <div class="d-flex flex-column align-items-start justify-content-start">
          <i class="font-size-h6 text-start font-weight-bold" style="text-align: center" v-translate> Supported file types: XLS, XLSX</i>
          <i class="font-size-h6 text-start font-weight-bold" style="text-align: center" v-translate> Supported file lines number: {{ maxFileLines }}</i>
          <i class="font-size-h6 text-start font-weight-bold" style="text-align: center">
            <translate>The date of the last file update must be less than</translate> {{ maxHoursFileAge }} <translate>hours</translate>
          </i>
        </div>

        <!-- Lien vers un exemple de fichier -->
        <b-button variant="link" :href="exampleFileUrl" target="_blank" rel="noopener" class="p-3 d-flex align-items-center custom-file-button">
          <i class="fas fa-file-xls icon-xl mr-2 text-primary"></i>
          <span class="font-size-h6 font-weight-bold text-primary" v-translate>{{ exampleFileLabel }}</span>
        </b-button>
      </div>

      <!-- utiliser pour l'icone fa-file-xls-->
      <link rel="stylesheet" href="https://site-assets.fontawesome.com/releases/v6.5.1/css/all.css" />
    </template>
  </b-card>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      isDragOver: false,
      isLoaded: false,
      allowedFileTypes: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
      pastedContent: "",
    };
  },

  props: {
    exampleFileLabel: {
      type: String,
      required: true,
    },
    exampleFileUrl: {
      type: String,
      required: true,
    },
    maxHoursFileAge: {
      type: Number,
      required: true,
    },
    maxFileLines: {
      type: Number,
      required: true,
    },
  },

  methods: {
    handleDragEvent(event, isDragging) {
      event.preventDefault();
      this.isDragOver = isDragging;
    },

    onDrop(event) {
      this.handleDragEvent(event, false);
      this.processFiles(event.dataTransfer.files);
    },

    browseFile() {
      const input = this.$refs.fileInput;
      input.click();
      const handleChange = (e) => {
        this.processFiles(e.target.files);
        input.value = "";
        input.removeEventListener("change", handleChange);
      };
      input.addEventListener("change", handleChange, { once: true });
    },

    async processFiles(files) {
      if (files.length !== 1 || files[0].size === 0) {
        this.showFileValidationError(this.$gettext("Please select one valid file."));
        return;
      }

      const file = files[0];
      this.isLoaded = true;

      // Validation du fichier
      if (Date.now() - file.lastModified >= this.maxHoursFileAge * 3600000) {
        this.showFileValidationError(`File too old. Must be less than ${this.maxHoursFileAge} hours.`);
        this.isLoaded = false;
        return;
      }

      if (!this.allowedFileTypes.includes(file.type)) {
        this.showFileValidationError("Use XLS or XLSX files only.");
        this.isLoaded = false;
        return;
      }
      try {
        const fileContent = await this.parseFileToCSV(file);
        this.$emit("fileDropped", fileContent);
      } catch (error) {
        this.showFileValidationError(error.message);
      } finally {
        this.isLoaded = false;
      }
    },

    async parseFileToCSV(file) {
      const data = await this.readFileAsArrayBuffer(file);
      const workbook = XLSX.read(data, { type: "array", cellDates: true, dateNF: "DD/MM/YYYY" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const csvContent = XLSX.utils.sheet_to_csv(worksheet, {
        header: 1,
        blankrows: false,
        dateNF: "DD/MM/YYYY",
        cellDates: true,
        FS: "¤",
        forceQuotes: true,
      });

      // Diviser le contenu CSV en lignes
      const rawLines = csvContent.split(/\r\n|\r|\n/);
      if (rawLines.length === 0) {
        throw new Error("Le fichier est vide.");
      }

      // Traiter la première ligne (en-têtes)
      const headers = rawLines[0].split("¤");
      const validColumnIndexes = [];
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].trim() !== "") {
          validColumnIndexes.push(i);
        }
      }

      const cleanedLines = [headers.filter((_, i) => validColumnIndexes.includes(i)).join("¤")];
      let lineCount = 0;

      // Traiter les lignes de données
      for (let i = 1; i < rawLines.length; i++) {
        if (lineCount > this.maxFileLines) {
          throw new Error(`Too many lines. Max: ${this.maxFileLines}.`);
        }

        const columns = rawLines[i].split("¤");
        const cleanedColumns = [];
        let hasData = false;

        for (let j of validColumnIndexes) {
          const value = columns[j] ? columns[j].trim() : "";
          cleanedColumns.push(value);
          if (value !== "") hasData = true;
        }

        if (hasData) {
          cleanedLines.push(cleanedColumns.join("¤"));
          lineCount++;
        }
      }

      // Joindre les lignes nettoyées en une chaîne CSV
      return cleanedLines.join("\n");
    },

    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(new Uint8Array(e.target.result));
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },

    showFileValidationError(errorMessage) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
      });
    },

    onPaste(event) {
      // Empêcher le comportement par défaut du collage
      event.preventDefault();

      // Récupérer le contenu collé
      const pastedText = event.clipboardData.getData("text");

      // Traiter le contenu collé
      this.processPastedContent(pastedText);
    },

    processPastedContent(content) {
      // Convertir le contenu collé en lignes
      let lines = content.split(/\r\n|\r|\n/);
      let cleanedLines = [];
      let validColumnIndexes = [];

      // Traiter la première ligne (en-têtes)
      let headers = lines[0].split("\t");
      for (let i = 0; i < headers.length; i++) {
        if (headers[i].trim() !== "") {
          validColumnIndexes.push(i);
        }
      }
      cleanedLines.push(validColumnIndexes.map((i) => headers[i].trim()).join("¤"));

      // Traiter les lignes de données
      for (let i = 1; i < lines.length; i++) {
        let line = lines[i].trim();
        if (line === "") continue; // Ignorer les lignes vides

        // Vérifier le nombre maximum de lignes
        if (cleanedLines.length > this.maxFileLines) {
          this.showFileValidationError(`Too many lines. Max: ${this.maxFileLines}.`);
          return;
        }

        // Nettoyer la ligne et supprimer les colonnes vides
        let columns = line.split("\t");
        let cleanedColumns = [];
        let hasData = false;

        for (let j of validColumnIndexes) {
          let value = j < columns.length ? columns[j].trim() : "";
          cleanedColumns.push(value);
          if (value !== "") hasData = true;
        }

        if (hasData) {
          cleanedLines.push(cleanedColumns.join("¤"));
        }
      }

      // Joindre les lignes nettoyées en une chaîne CSV
      const csvContent = cleanedLines.join("\n");

      // Émettre l'événement avec le contenu CSV nettoyé
      this.$emit("fileDropped", csvContent);
    },
  },
};
</script>

<style>
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop-area .svg-icon {
  margin-bottom: 20px;
}

.drop-area div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-area span {
  margin-bottom: 10px;
}

.drop-area b-button {
  margin-top: 10px;
}

.drop-area.drag-over {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

.custom-file-button i,
.custom-file-button span {
  transition: color 0.15s ease;
  color: var(--primary) !important;
}

.custom-file-button:hover i,
.custom-file-button:hover span {
  color: var(--primary-dark) !important; /* Définissez une couleur plus foncée */
}
</style>
